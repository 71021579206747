import React from "react"
import { Box } from "@lib/ui/core"
import Container from "@layouts/Container"
import Layout from "@layouts/MainLayout"
import TncHtml from "@assets/document/tnc"

const TncPage = () => {
  return (
    <Layout bgcolor="#14a1e9" homepage>
      <Box display="flex" width="100%">
        <Container>
          <Box bgcolor="#ffffff" my={2} p={2} borderRadius="32px">
            <Box fontSize="32px" fontWeight="700" align="center">
              使用條款
            </Box>
            <TncHtml />
          </Box>
        </Container>
      </Box>
    </Layout>
  )
}

export default TncPage
