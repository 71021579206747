import React from "react"

const Tnc = () => {
  return (
    <div>
      <p>
        Mathod提供個人化訂閱服務，讓會員使用可上網的電腦、平板電腦其他特定裝置，使用我們持續更新的題目庫。
      </p>
      <p>
        您對我們服務的使用受本《使用條款》約束。 本《使用條款》所稱「Mathod
        服務」、「我們的服務」或「服務」，是指 Mathod
        所提供搜尋及使用Mathod內容的個人化服務，包括所有特性與功能及網站與使用者介面，以及與我們服務相關的所有內容。
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>1.&nbsp; &nbsp; 會員資格</strong>
      </p>
      <p>&nbsp;</p>
      <p style={{ paddingLeft: "30px" }}>
        1.1您的Mathod 會員資格會一直延續直到終止為止。 要使用 Mathod
        服務，您必須連接至網路，且使用信用卡作為「付款方式」選購我們的服務。除非您在收費日期前取消會員資格，否則視為您授權我們按照您的付款方式向您收取下次收費週期的會費（請參閱下文的〈取消〉）。
      </p>
      <p>
        <br /> <br /> <strong>2.&nbsp; &nbsp; 收費與取消</strong>
      </p>
      <p style={{ paddingLeft: "30px" }}>
        <br />
        2.1.&nbsp;<u>收費週期。</u>
        Mathod將會以您的「帳戶」頁面載明的具體收費日期，依照您的付款方式向您收取會費。收費週期的期限&nbsp;
        將取決於您註冊服務時所選之訂閱類型。
        在某些情況下，您的付款日可能會變動，例如，您的付款方式並未結算成功，或您&nbsp;
        的付費會員資格啟用日期在某個月份沒有對應日期。會員可以前往我們的網站，並前往「用戶中心」，查看下一次的付款日&nbsp;
        期。
      </p>
      <p style={{ paddingLeft: "30px" }}>
        <br />
        2.2.&nbsp;<u>付款方式。</u>&nbsp;要使用 Mathod
        服務，您必須使用信用卡仍作為「付款方式」。
        若您的主要「付款方式」遭拒，或無法再
        供我們向您收取訂閱費用，您仍須為任何未付金額負有繳付責任。
        若因過期、資金不足或其他原因導致付款失敗，且您未取&nbsp;
        消帳戶，則我們可暫停您對服務的使用，直到我們成功透過您有效的「付款方式」收費為止。針對某些付款方式，發行機構&nbsp;
        可能會向您收取特定費用，例如跨國交易手續費或其他與您付款方式有關的處理費用。
        當地稅額可能因您所使用的付款方式 而有所不同。
        詳情請諮詢您的付款方式服務提供者。
        <br /> <br />
        2.3.&nbsp;<u>更新付款方式。</u>
        &nbsp;您可前往「帳戶」頁面更新您的「付款方式」。
        我們亦可使用付款服務提供者所提供的資訊，來更新 您的「付款方式」。
        您完成任何更新後，即授權我們繼續透過您所適用的「付款方式」收費。
        <br /> <br />
        2.4.&nbsp;<u>取消。</u>&nbsp;您可隨時取消您的 Mathod
        會員資格，而在當次收費週期結束前，您仍可繼續使用 Mathod 服務。
        在相關適用法律許可之範圍內，付款不可退還，且對於未滿一個會員訂閱期或未使用的
        Mathod 服務的會員，我們不予退款或抵用。
        若要取消，請前往「用戶中心」頁面並按照指示操作。
        如果您取消會員資格，則您的帳戶將於目前收費週期結束時自動關閉。若要查看您的帳戶何時將關閉，請點選「用戶中心」頁面上的「帳戶資料」。{" "}
        <br /> <br />
        2.5.&nbsp;<u>變更價格與訂閱方案。</u>
        &nbsp;我們可不定期地變更訂閱方案與服務價格；但任何價格或訂閱方案地變更須在通知您至少
        30 天 後方能生效。
      </p>
      <p style={{ paddingLeft: "30px" }}>&nbsp;</p>
      <p>
        3.&nbsp; &nbsp; Mathod <strong>服務</strong>
      </p>
      <p>&nbsp;</p>
      <p style={{ paddingLeft: "30px" }}>
        3.1 Mathod 服務及透過 Mathod
        服務所享用的任何內容僅供您個人使用，不可作為商業用途，也不得分享予您家庭成員以外的人士。
        在您的 Mathod
        會員資格有效期間內，我們授予您有限、非專屬、不得轉讓的權利，以使用
        Mathod 服務。除上述事項外，我們不向您轉讓任何權利、所有權或利益。 <br />{" "}
        <br />
        3.2. Mathod 服務（包括題目庫）會定期更新。
        此外，我們亦將會持續針對本服務各方面進行測試，包括網站、使用面、促銷優惠及
        Mathod 內容的可得性。
      </p>
      <p style={{ paddingLeft: "30px" }}>
        <br />
        3.3.
        您同意根據所有適用法律、規則和規範或針對此服務或內容的其他使用限制規定使用
        Mathod 服務，包括與其相關的所有特色和功能。
        您同意不以任何方式存檔、重製、散布、修改、出版、授權、製作衍生作品、出售或使用（除非本《使用條款》明確授權）Mathod
        服務所含的內容和資訊或從 Mathod 服務或透過 Mathod 取得的內容和資訊。
        您還同意不：規避、移除、更改、停用、降級或妨礙 Mathod
        服務的任何內容保護措施；使用任何網路機器人 (robot)、網路蜘蛛
        (spider)、網路抓取 (scraper) 程式或其他自動化方式來取用 Mathod
        服務；將透過 Mathod
        服務取得的任何軟體或其他產品或程式進行反解譯、進行還原工程或反組譯；插入任何程式碼或產品或以任何方式操控
        Mathod 服務的內容；或使用任何資料探勘、資料收集或資料擷取方法。
        此外，您同意不上傳、發佈、以電郵傳送或以其他方式發送或傳輸任何旨在干擾、破壞或限制與
        Mathod
        服務相關的任何電腦軟體或硬體或電信設備功能的資料，包括任何軟體病毒或其他任何電腦程式碼、檔案或程式。
        如果您違反本《使用條款》或參與非法或詐欺使用服務，則我們可終止或限制您使用我們的服務。
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>4.&nbsp; &nbsp; 密碼與帳戶存取。</strong>
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p style={{ paddingLeft: "30px" }}>
        建立 Mathod 帳戶且付款方式已完成扣款的會員（「帳戶持有人」）可存取並控制
        Mathod 帳戶，以及用於存取我們服務的Mathod相容裝置，且須對該 Mathod
        帳戶上所發生的任何活動負責。
        為保持對帳戶的控制並防止任何人存取帳戶（包括帳戶的使用記錄資訊），帳戶持有人應對為取得服務所使用之
        Mathod相容裝置，掌握其控制權，不得將其帳戶的密碼或付款方式的詳細資料洩漏給任何人。
        對於您向我們提供的帳戶相關資訊，您有責任更新並保持其準確性。
        我們可能會終止或暫停您的帳戶，以便保護您、Mathod
        或合作夥伴免受身份盜竊或其他詐欺活動的損害。
      </p>
      <p>&nbsp;</p>
      <p>
        &nbsp;<strong>5.&nbsp; &nbsp; 擔保與責任限制。</strong>
      </p>
      <p>&nbsp;</p>
      <p style={{ paddingLeft: "30px" }}>
        Mathod 服務是以「現狀」提供，不提供任何擔保並不附條件。
        特別是，我們的服務不可能不受到干擾或不會出現錯誤。
        您同意放棄對我們請求任何特殊、間接及衍生性的損害賠償。
        這些條款不限制您依據居住國家/地區法律所享有的任何不可放棄的擔保或消費者權利。
      </p>
      <p style={{ paddingLeft: "30px" }}>&nbsp;</p>
      <p>
        <strong>6.&nbsp; &nbsp; 雜項</strong>
      </p>
      <p style={{ paddingLeft: "30px" }}>
        <br /> <br />
        6.1.&nbsp;<u>使用條款之變更與轉讓。</u>Mathod 可不時修改本《使用條款》。
        我們會在您適用上述變更的至少 30 天前發出通知。
        我們可能隨時轉讓或移轉我們與您簽訂的協議，其中包括與我們相關的權利和義務，而且您同意就這類轉讓或移轉的相關事宜與我們合作。
        <br /> <br /> 6.2.&nbsp;<u>電子通訊。</u>
        &nbsp;我們只會以電子形式向您發送帳戶相關資訊（如付款授權、發票、密碼或付款方式更改、確認訊息、通知），如透過電郵寄到您在註冊時所提供的電郵地址。
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>最近更新時間：</strong>2020/12/16
      </p>
      <p>&nbsp;</p>
    </div>
  )
}

export default Tnc
